<template>
  <div class="news-center">
    <div class="container">
      <div class="Header-item"><Header /></div>
      <div class=""><Carousel /></div>
      <div class="news-content-web">
        <div class="tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-bind:label="$t('lang.newcenter.menu1')" name="0">
              <div v-if="!isdetail">
                <div
                  class="item"
                  v-for="(item, index) in newsList"
                  :key="index"
                >
                <div class="item-img-square">
                  <img :src="item.img" alt="" />
                </div>
                  <div class="item-content">
                    <div class="title-data">
                      <div class="title">{{ item.title }}</div>
                      <div class="data">{{ item.update_time }}</div>
                    </div>
                    <p class="item-text">{{ item.digest }}</p>
                    <p class="detail" @click="getViewdetails(item)">
                      {{$t('lang.newcenter.viewdetails')}} <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="details-all">
                  <div class="title">{{newsdetails.title}}</div>
                  <div class="timedetails">
                    <div class="time"><img class="img" src="../assets/icon_date.png"/>{{newsdetails.create_time}}</div>
                    <div class="share"><img class="img" src="../assets/icon_share.png"/>分享</div>
                  </div>
                  <div class="details-centent ql-editor" v-html="newsdetails.content"></div>
                  <div class = 'return' @click="getReturn">
                  <img class="img" src="../assets/bluereturn.png"/>
                  {{$t('lang.newcenter.return')}}</div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane v-for="newsitem in newstype" :key="newsitem.id" :label="newsitem.title">
              <div v-if="!isdetail">
                <div
                  class="item"
                  v-for="(item, index) in newsList"
                  :key="index"
                >
                  <img :src="item.img" alt="" class="item-img-square" />
                  <div class="item-content">
                    <div class="title-data">
                      <div class="title">{{ item.title }}</div>
                      <div class="data">{{ item.update_time }}</div>
                    </div>
                    <p class="item-text">{{ item.digest }}</p>
                    <p class="detail" @click="getViewdetails(item)">
                      {{$t('lang.newcenter.viewdetails')}} <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="details-all">
                  <div class="title">{{newsdetails.title}}</div>
                  <div class="timedetails">
                    <div class="time"><img class="img" src="../assets/icon_date.png"/>{{newsdetails.create_time}}</div>
                    <div class="share"><img class="img" src="../assets/icon_share.png"/>分享</div>
                  </div>
                  <div class="details-centent" v-html="newsdetails.content"></div>
                  <div class = 'return' @click="getReturn">
                  <img class="img" src="../assets/bluereturn.png"/>
                  {{$t('lang.newcenter.return')}}</div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="pagination" v-if="!isdetail">
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :total="total"
            :page-size="pagesize"
          >
          </el-pagination>
        </div>
      </div>
      <!-- h5手机页面 -->
      <div class="news-content-mob">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('lang.newcenter.menu1')" name="0">
            <div class="tabs">
              <div class="item" v-for="(item, index) in newsList" :key="index">
                <div class="item-content-mob">
                  <img :src="item.img" alt="" class="item-img-mob" />
                  <div class="item-mob-title">{{ item.title }}</div>
                  <div class="item-mob-text">{{ item.digest }}</div>
                </div>
                <div class="item-mob-bottom">
                  <p class="data">{{ item.update_time }}</p>
                  <p class="detail" @click="getViewdetailsmob(item)">
                    {{ $t('lang.newcenter.viewdetails')}} <i class="el-icon-arrow-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-for="newsitem in newstype" :key="newsitem.id" :label="newsitem.title">
            <div class="tabs">
              <div class="item" v-for="(item, index) in newsList" :key="index">
                <div class="item-content-mob">
                  <img :src="item.img" alt="" class="item-img-mob" />
                  <div class="item-mob-title">{{ item.title }}</div>
                  <div class="item-mob-text">{{ item.digest }}</div>
                </div>
                <div class="item-mob-bottom">
                  <p class="data">{{ item.update_time }}</p>
                  <p class="detail" @click="getViewdetailsmob(item)">
                    {{ $t('lang.newcenter.viewdetails')}} <i class="el-icon-arrow-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          :loading="loading"
          class="btn"
          @click="handleLoadData()"
          >{{ loadtext }}</el-button
        >
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import Copyright from '@/components/Copyright/Copyright.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'NewsCenter',
  components: {
    Header,
    Carousel,
    Copyright
  },
  data () {
    return {
      activeName: '0',
      newsList: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      total_page: 1,
      date: [],
      loading: false,
      loadtext: '加载更多',
      isdetail: false, // 是否是查看详情的页面
      newsdetails: {},
      newstype: [],
      newsIndex: 0 // 新闻类型
    }
  },
  watch: {
    getLangIndex (to, from) {
      this.getNewstype()
    }
  },
  computed: {
    ...mapGetters(['getIsPc', 'getLangIndex', 'getLangSelect'])
  },
  methods: {
    // 获取新闻类型
    async getNewstype () {
      await this.$axios.post('api/news/getnewstypelist', { lang: this.getLangSelect }).then(res => {
        this.newstype = res.data.data
        this.newsIndex = this.activeName === '0' ? this.activeName : this.newstype[this.activeName - 1].id
      }).then(() => {
        // 请求新闻列表
        this.getListnews()
      })
    },
    // 点击返回列表
    getReturn (e) {
      this.isdetail = false
    },
    // pc端获取详情
    getViewdetails (item) {
      this.$router.push({ path: 'newschild', query: { id: item.id } })
    },
    // 移动端的查看详情
    getViewdetailsmob (e) {
      this.$router.push({ path: 'newscenter', query: { id: e.id } })
    },
    handleClick (e) {
      if (e.index === '0') {
        this.newsIndex = e.index
      } else {
        this.newsIndex = this.newstype[e.index - 1].id
      }
      this.currentPage = 1
      this.getListnews()
    },
    // 获取新闻列表的公共方法
    getListnews () {
      // this.newsList = []
      let listIndex = []
      if (this.newsList.length > 0) {
        listIndex = this.newsList
      }
      const param = {}
      param.lang = this.getLangSelect
      param.page_no = this.currentPage
      param.page_size = this.pagesize
      param.type = this.newsIndex
      this.$axios.post('api/news/getnewslist', param).then(res => {
        const list = res.data.data
        this.currentPage = list.page_no
        this.pagesize = list.page_size
        this.total = list.total_count
        this.total_page = list.total_page
        this.newsList = list.data
        if (this.getIsPc) {
          this.newsList = list.data
        } else { // 手机页面
          if (list.data.length !== 0) {
            this.loading = false
            this.loadtext = '加载更多'
          } else {
            this.loading = false
            this.loadtext = '没有更多了'
          }
          if (list.page_no === 1) {
            this.newsList = list.data
          } else {
            this.newsList = [...listIndex, ...list.data]
          }
        }
      })
    },
    handleSizeChange (val) {
      // this.pagesize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getListnews()
    },
    handleLoadData () {
      this.loading = true
      this.loadtext = '正在加载'
      if (this.currentPage >= this.total_page) {
        this.loading = false
        this.loadtext = '没有更多了'
      } else {
        this.currentPage++
        this.getListnews()
      }
    }
  },
  created () {
    this.$store.dispatch('setNavIndex', 3)
    this.getNewstype()
  }
}
</script>
<style scoped lang="less">
.news-center {
  .news-content-web {
    width: 1200px;
    max-width: 1200px;
    margin: auto;
    .tabs {
      min-height: 60vh;
      .item {
        display: flex;
        padding-top: 6vh;
        height: 213px;
        .item-img-square {
          width: 300px;
          height: 200px;
          flex: none;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .item-img-square:hover {
         /*  box-shadow: 5px 5px 10px #888888; */
          transform: scale(1.02);
          transition:all 0.6s
        }
        .item-content {
          flex: 1 0 auto;
          position: relative;
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          .title-data {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            font-weight: bold;
            color: #333;
            max-width: 885px;
            overflow: hidden;
            .title {
              line-height: 30px;
              flex: 1;
              max-width: 85%;
              flex-wrap: wrap;
              overflow: hidden;
            }
            .data {
              min-width: 120px;
              color: #999;
            }
          }
          .item-text {
            font-size: 15px;
            margin: 1vh 0;
            color: #999;
            max-width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-wrap: break-word;
          }
          .detail {
            font-size: 15px;
            color: #002537;
            position: absolute;
            bottom: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination {
    padding: 3vh 0 8vh 0;
    display: flex;
    justify-content: center;
  }
  .news-content-mob {
    display: none;
    .tabs {
      background: #efefef;
      .item {
        background: white;
        margin-bottom: 8px;
        border-radius: 8px;
        .item-content-mob {
          padding: 16px 16px 0 16px;
          .item-img-mob {
            width: 100%;
            border-radius: 5px;
          }
          .item-mob-title {
            font-size: 15px;
            font-weight: bold;
            color: #222;
            padding-top: 1vh;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap:break-word
          }
          .item-mob-text {
            font-size: 11px;
            color: #999;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap:break-word
          }
        }
        .item-mob-bottom {
          border-top: 1px solid #efefef;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
          padding: 0 16px;
          margin-top: 8px;
          .data {
            font-size: 13px;
            color: #999;
          }
          .detail {
            font-size: 13px;
            color: #002537;
          }
        }
      }
    }
    .btn {
      background: white;
      width: 100%;
      text-align: center;
      border: none;
      color: #222;
    }
  }
}
.container{
  .Header-item{
    position: absolute;
    z-index: 10;
    width: 100%;
  }
}
.details-all{
    width: 640px;
    margin: auto;
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      padding-top: 60px;
      margin-bottom: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    .timedetails{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .time,.share{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        .img{
          width:32px;
          height:32px;
          margin-right: 3px;
        }
      }
      .share{
        font-size: 15px;
        font-weight: 400;
        color: #0DC102;
      }
    }
  .details-centent{
    overflow: hidden;
    img{
      width:100%;
    }
  }
  .return{
    font-size: 22px;
    font-weight: 500;
    color: #019EFA;
    text-align: right;
    margin-top: 65px;
    margin-bottom: 110px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img{
      width: 25px;
    }
  }
}
</style>
<style lang="less">
.details-centent /deep/  p {
    width:100%;
}
.details-centent /deep/ img {
    width:100%;
}
</style>
